import {Component, OnInit} from '@angular/core';
import {EmploymentPositionLandingPageData} from "../../../../generated/data";
import {environment} from "../../../../environments/environment";
import {PublicEmploymentPositionResource} from "../../../../generated/resources";
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-position-indexing',
  standalone: true,
    imports: [
        CommonModule
    ],
  templateUrl: './position-indexing.component.html',
  styleUrl: './position-indexing.component.scss'
})
export class PositionIndexingComponent implements OnInit {

    jobs: EmploymentPositionLandingPageData[]
    loading: boolean = false

    talentagentApiUrl = environment.apiUrl

    constructor(
        private publicEmploymentPositionResource: PublicEmploymentPositionResource,
    ) { }

    ngOnInit(): void {
        this.getJobs()
    }

    getJobs() {
        this.loading = true
        this.publicEmploymentPositionResource.getAllEmploymentPositionsForIndexing().then(result => {
            this.jobs = result
            this.loading = false
        })
    }

    getPositionHref(jobId: number): string {
        return `${window.location.origin}/position-details/${jobId}`
    }

}
