<div class="content-container wrapper flex row flex-space-between content-padding" *ngIf="!loading">
    <div *ngIf="jobs && !loading" class="jobs-carousell flex row-wrap space-around">
    <a *ngFor="let job of jobs" [href]="getPositionHref(job.id)" class="job flex row start" >
        <div class="logo" [ngStyle]="{'background-image': 'url(' + talentagentApiUrl + '/public/companyLogo/' + job.id + ')'}"></div>
        <div>
            <div class="title">{{job.title}}</div>
            <div class="company-name">{{job.hiringCompanyName}}</div>
            <div class="information">
                <div class="flex row start">
                    <div><i class="fas fa-map-marker-alt"></i></div>
                    <div class="location">{{job.locationDescription}}</div>
                </div>
            </div>
        </div>
    </a>
</div>
</div>
